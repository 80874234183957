import { setConfiguration } from 'react-grid-system';

export const breakpoints = {
  sm: '485px',
  md: '550px',
  lg: '900px',
  xl: '1120px',
  xxl: '1500px',
} as const;

export const gutterWidth = '15.5px' as const;

const containerWidths = Object.values(breakpoints).map(value =>
  parseInt(value, 10),
);

setConfiguration({
  breakpoints: containerWidths,
  gutterWidth: parseInt(gutterWidth, 10),
  containerWidths,
});
