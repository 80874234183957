import { ScreenClassProvider } from 'react-grid-system';

import {
  GlobalStyles,
  KeyboardDetectionProvider,
} from '@spotify-internal/encore-web';
import type { AppProps } from 'next/app';

import '@/gridConfiguration';

import '@spotify-internal/encore-web/css/fonts.css';
import '@spotify-internal/encore-web/css/encore-megaphone-light-theme.css';
import '@/styles/globals.scss';

const CustomApp = ({ Component, pageProps }: AppProps) => {
  return (
    <KeyboardDetectionProvider>
      <div className="encore-megaphone-light-theme">
        <ScreenClassProvider>
          {/* CSS Reset wrapped into a styled-component */}
          <GlobalStyles />
          <Component {...pageProps} />
        </ScreenClassProvider>
      </div>
    </KeyboardDetectionProvider>
  );
};

export default CustomApp;
